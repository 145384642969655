import { createTheme } from "@mui/material/styles"
// https://mui.com/customization/default-theme/#explore

const colors = {
  blue: "#352f7c",
  blue2: "#0076c0",
  blue3: "#55c2ff",
  blue4: "#D6EAF2",
  blue5: "#E3F1F4",
  blue6: "#e5f3ff",
  blue7: "#63b0f8",
  blue_light: "#2f80ed",
  purple: "#796a9a",
  gray: "#cfcfd9",
  gray2: "#e0e0e0",
  gray3: "#eaeaea",
  gray4: "#999999",
  gray5: "rgba(0, 0, 0, 0.12)",
  brown: "#9c9999",
  yellow: "#fac043",
  yellow2: "#ffedde",
  yellow3:"#fd7e14",
  yellow_dark: "#B68720",
  green: "#0a792d",
  green_light: "#34ae60",
  green2: "#e4f0f4",
  red: "#f66361",
  red2: "#ec2323",
  red3: "#fff0f0",
  red_dark: "#eb5757",
  white: "#ffffff",
  paper: "#fcfcfc",
  black: "#2f2e41",
  black2: "#333333",
  black3: "#666666",
  black4: "#000000",
  transparent: "transparent"
}

const theme = createTheme({
  spacing: 1,
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 1024,
      lg: 1200,
      xl: 1536
    }
  },
  palette: {
    primary: {
      main: colors.yellow3,
      light: colors.yellow3,
      dark: colors.yellow_dark,
      contrastText: colors.white
    },
    secondary: {
      main: colors.gray,
      light: colors.gray,
      dark: colors.gray,
      contrastText: colors.white
    },
    error: {
      main: colors.red,
      dark: colors.red_dark
    },
    info: {
      main: colors.blue,
      light: colors.blue_light
    },
    success: {
      main: colors.green,
      dark: colors.green,
      light: colors.green_light
    },
    text: {
      default: colors.black2,
      white: colors.white,
      red2: colors.red2,
      gray4: colors.gray4,
      black2: colors.black2,
      black3: colors.black3,
      black4: colors.black4,
      blue_light: colors.blue_light,
      blue2: colors.blue2,
      blue3: colors.blue3,
      blue7: colors.blue7,
      yellow3: colors.yellow3
    },
    divider: colors.yellow,
    background: {
      default: colors.white,
      black4: colors.black4,
      paper: colors.paper,
      green2: colors.green2,
      red2: colors.red2,
      red3: colors.red3,
      yellow: colors.yellow,
      yellow2: colors.yellow2,
      gray2: colors.gray2,
      gray3: colors.gray3,
      gray5: colors.gray5,
      blue2: colors.blue2,
      blue4: colors.blue4,
      blue5: colors.blue5,
      blue6: colors.blue6
    }
  },
  typography: {
    button: {
      textTransform: "none"
    }
  },
  border: {
    yellow: colors.yellow,
    yellow3: colors.yellow3,
    red: colors.red,
    red2: colors.red2,
    gray3: colors.gray3,
    transparent: colors.transparent
  },
  customZIndex: {
    header: 1000,
    footer: 1000,
    statusBar: 1000,
    sideBar: 1200,
    modal: 1300
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          // Some CSS
          borderRadius: "12px",
          marginTop: "4px",
          background: colors.white
        }
      }
    }
  }
})

export default theme
