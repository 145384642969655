import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit"
import type { TText } from "@constants"
import { EventApi } from "../apis"

export const getEventSetting = createAsyncThunk("get/event/setting", async (payload: string, { rejectWithValue }) => {
  try {
    let res = await EventApi.getEventSetting(payload)
    return res.data
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const getEventTeamTypes = createAsyncThunk(
  "get/event/team-types",
  async (payload: string, { rejectWithValue }) => {
    try {
      let res = await EventApi.getEventTeamTypes(payload)
      return res.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const getEventDonationSubpage = createAsyncThunk(
  "get/event/donation-subpage",
  async (payload: string, { rejectWithValue }) => {
    try {
      let res = await EventApi.getEventDonationSubpage(payload)
      return res.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const getEventDiscounts = createAsyncThunk(
  "get/event/discounts",
  async (payload: { eventId: number; discountCode: string }, { rejectWithValue }) => {
    try {
      let res = await EventApi.getEventDiscounts(payload)
      return res.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export interface IInitialState {
  data: {
    id: number
    name: TText
    slug: string
    openForDonation: boolean
    description: TText
    priceInCents: EventApi.TCents
    minDonationInCents: EventApi.TCents
    maxNumOfTeamParticipants: number
    tnc: TText
    privacy: TText
    merchandises: EventApi.Tmerchandises
    [key: string]: any
  }
  teamTypeOption: EventApi.IgetEventTeamTypes[] | null
  donationSubpage: EventApi.IgetEventDonationSubpage
  loaded: boolean
}

const initialState: IInitialState = {
  data: {
    id: 0,
    name: {
      en: "",
      "zh-HK": ""
    },
    slug: "",
    openForDonation: false,
    description: {
      en: "",
      "zh-HK": ""
    },
    priceInCents: {
      individual: 0,
      team: 0
    },
    minDonationInCents: {
      individual: 0,
      team: 0
    },
    maxNumOfTeamParticipants: 0,
    tnc: {
      en: "",
      "zh-HK": ""
    },
    privacy: {
      en: "",
      "zh-HK": ""
    },
    merchandises: []
  },
  teamTypeOption: null,
  donationSubpage: {
    eventId: 0,
    name: {
      en: "",
      "zh-HK": ""
    },
    desc: {
      en: "",
      "zh-HK": ""
    },
    content: {
      en: "",
      "zh-HK": ""
    }
  },
  loaded: false
}

const eventSlice = createSlice({
  name: "event",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getEventSetting.fulfilled, (state, { payload }: PayloadAction<EventApi.IgetEventSetting>) => {
      payload.priceInCents = {
        individual: getCents(payload.priceInCents.individual),
        team: getCents(payload.priceInCents.team)
      }
      payload.minDonationInCents = {
        individual: getCents(payload.minDonationInCents.individual),
        team: getCents(payload.minDonationInCents.team)
      }
      state.data = payload
      state.loaded = true
    })
    builder.addCase(getEventTeamTypes.fulfilled, (state, { payload }: PayloadAction<EventApi.IgetEventTeamTypes[]>) => {
      payload.forEach((item) => (item.label = item.typeName))
      state.teamTypeOption = payload
    })
    builder.addCase(
      getEventDonationSubpage.fulfilled,
      (state, { payload }: PayloadAction<EventApi.IgetEventDonationSubpage>) => {
        state.donationSubpage = payload
      }
    )
  }
})

const getCents: (number) => number = (price) => {
  return parseInt((price / 100).toString())
}

export const {} = eventSlice.actions
export default eventSlice.reducer
