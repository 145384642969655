import http from "./apiServices"
import { ILabel, TText, TImage } from "@constants"

export type TCents = {
  individual: number
  team: number
}

export interface IgetEventSetting {
  id: number
  name: TText
  slug: string
  description: TText
  banner: {
    en: {
      id: string
      url: string
    }
    "zh-HK": {
      id: string
      url: string
    }
  }
  openForDonation: boolean
  location: TText
  priceInCents: TCents
  minDonationInCents: TCents
  publicationStartedAt: string
  publicationEndedAt: string
  registrationStartedAt: string
  registrationEndedAt: string
  startedAt: string
  endedAt: string
  maxNumOfTeamParticipants: number
  tnc: TText
  privacy: TText
  rules: [
    {
      name: TText
      description: TText
      order: number
    }
  ]
  corporates: {
    image: TImage
    description: TText
    order: number
  }[]
  prizes: {
    image: TImage
    description: TText
    order: number
  }[]
  sponsors: {
    image: TImage
    description: TText
    order: number
  }[]
  createdAt: string
  updatedAt: string
  hasStarted: boolean
  hasEnded: boolean
  merchandises: Tmerchandises
}

export type Tmerchandise = {
  id: number
  name: TText
  priceInCents: number
  images: [
    {
      id: number
      image: {
        id: string
        url: string
      }
      order: number
    }
  ]
  optionType: {
    id: number
    title: TText
    options: [
      {
        id: number
        name: TText
        order: number
      }
    ]
  } | null
  order: number
  isHidden: boolean
}

export type Tmerchandises = [] | Tmerchandise[]

export interface IgetEventTeamTypes {
  id: number
  eventId: number
  typeName: ILabel
  label?: ILabel
  extraFieldLabel: ILabel
  isExtraFieldRequired: boolean
  createdAt: string
  updatedAt: string
  deletedAt: string
}

export interface IgetEventDonationSubpage {
  eventId: number
  name: TText
  desc: TText
  content: TText
}

export interface IgetEventDiscounts {
  deductAmtInCents: number
}

export const getEventSetting = (slug: string) => {
  return http.get<IgetEventSetting>(`events/${slug}/setting`)
}

export const getEventTeamTypes = (slug: string) => {
  return http.get<IgetEventTeamTypes[]>(`events/${slug}/team-types`)
}

export const getEventDonationSubpage = (slug: string) => {
  return http.get<IgetEventDonationSubpage>(`events/${slug}/donation-subpage`)
}

export const getEventDiscounts = ({ eventId, discountCode }: { eventId: number; discountCode: string }) => {
  return http.get<IgetEventDiscounts>(`events/${eventId}/discounts/${discountCode}`)
}
