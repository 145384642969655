import http from "./apiServices"

export interface IgetSetting {
  results: TItemSetting[]
}

export type TItemSetting = {
  id: string
  value: {
    en: string
    "zh-HK": string
  }
  createdAt: string
  updatedAt: string
}

export const getSetting = () => {
  return http.get<IgetSetting>(`settings`)
}
