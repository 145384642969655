import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit"
import { SettingApi } from "../apis"

export const getSetting = createAsyncThunk("get/setting", async (payload, { rejectWithValue }) => {
  try {
    let res = await SettingApi.getSetting()
    return res.data
  } catch (error) {
    return rejectWithValue(error)
  }
})

export interface IInitialState {
  data: {
    [key: string]: SettingApi.TItemSetting
  }
  loaded: boolean
}

const initialState: IInitialState = {
  data: {
    privacy: {
      id: "",
      value: {
        en: "",
        "zh-HK": ""
      },
      createdAt: "",
      updatedAt: ""
    },
    tnc: {
      id: "",
      value: {
        en: "",
        "zh-HK": ""
      },
      createdAt: "",
      updatedAt: ""
    }
  },
  loaded: false
}

const settingSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSetting.fulfilled, (state, { payload }: PayloadAction<SettingApi.IgetSetting>) => {
      payload?.results?.forEach((item) => {
        state.data[item.id] = item
      })
      state.loaded = true
    })
  }
})

export const {} = settingSlice.actions
export default settingSlice.reducer
