import http from "./apiServices"

export interface IpostRegistrations {
  eventId: number
  unitPriceInCents: number
  donationInCents: number
  team?: {
    name: "string"
    maxNumOfTeamParticipants: number
    type: "string"
  }
  participants: {
    email: "string"
    phone: "string"
    firstName: "string"
    lastName: "string"
    gender: "string"
    dob: "string"
  }[]
  failedStripeId: string
}

export const postRegistrations = (payload: IpostRegistrations) => {
  return http.post("registrations", payload)
}

export interface IputRegistrationsPaymentResult {
  stripeId: string
  paymentResult: string
}

export const putRegistrationsPaymentResult = (payload: IputRegistrationsPaymentResult) => {
  return http.put(`registrations/${payload.stripeId}/${payload.paymentResult}`)
}
