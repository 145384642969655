import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { RegistrationApi } from "../apis"

export const postRegistrations = createAsyncThunk(
  "post/registrations",
  async (payload: RegistrationApi.IpostRegistrations, { rejectWithValue }) => {
    try {
      let res = await RegistrationApi.postRegistrations(payload)
      return res
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const putRegistrationsPaymentResult = createAsyncThunk(
  "put/registrationsPaymentResult",
  async (payload: RegistrationApi.IputRegistrationsPaymentResult, { rejectWithValue }) => {
    try {
      let res = await RegistrationApi.putRegistrationsPaymentResult(payload)
      return res
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

interface IInitialState {}

const initialState: IInitialState = {} as const

const registrationSlice = createSlice({
  name: "registration",
  initialState,
  reducers: {},
  extraReducers: (builder) => {}
})

export const {} = registrationSlice.actions
export default registrationSlice.reducer
